import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import Helmet from "react-helmet"

export const query = graphql`
    query competitors {
        wpcontent {
            competitors(first: 99) {
                nodes {
                    id
                    title
                    uri
                    excerpt
                    thumbnail
                }
            }
            seo{
                contentTypes {
                    competitor {
                        metaDesc
                        title
                    }
                }
            }
        }
    }
`

const competitors = ({ data }) => {
    const competitors = data.wpcontent.competitors.nodes
    const competitorSeo = data.wpcontent.seo.contentTypes.competitor
    const pageTitle = competitorSeo.title
    const metaDesc = competitorSeo.metaDesc

    return (
        <Layout>
            <Helmet>
                <title>{pageTitle}</title>

                <meta name="description" content={metaDesc} />

                <meta property="og:title" content={pageTitle} />

                <meta property="og:description" content={metaDesc} />

                <meta property="twitter:title" content={pageTitle} />
                        
                <meta property="twitter:description" content={metaDesc} />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/competitors/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <link rel="canonical" href="https://www.vantagecircle.com/competitors/"></link>
            </Helmet>
            <section className="bg-indigo-100  mx-auto py-10 pb-0 sm:py-20 sm:pb-0 md:py-40 relative">
                <div className="container md:flex md:flex-col justify-center items-center lg:flex-row lg:justify-between max-w-7xl text-center lg:text-left px-6 pb-10">
                   <div className = "flex flex-col w-full lg:w-1/2 justify-center lg:justify-start">
                        <h1 className = "text-2xl lg:text-7xl text-gray-100 font-bold">Vantage Circle v/s Other Alternatives</h1>
                        <p className = "text-lg 2xl:text-xl text-gray-100 mt-3">Discover why Vantage Circle is the best alternative to other existing employee engagement solutions on the market.</p>
                   </div>
                   <div className = "w-full lg:w-1/2 flex justify-end items-center">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/11/Product-Update-hero-img-1.png" height= "257" width="483" className = "" />
                    </div>
                </div>
                <div className = "absolute -left-16 -top-16 hidden lg:flex">
                    <img src= "https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/circle-white.png" height="140" width = "160"/>
                </div>
                <div className = "absolute -right-16 -bottom-16 hidden lg:flex">
                    <img src= "https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/circle-white.png" height="140" width = "160"/>
                </div>
            </section>
            <section className="resource-listing container px-6 lg:px-0 max-w-7xl mx-auto pt-5 sm:pt-10 md:pt-40 md:-mt-60">
                <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    {competitors.map(competitor => (
                        <article key={competitor.id}>
                            <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                                <img loading="lazy" className="pb-0 rounded-sm border border-purple-800" src={competitor.thumbnail} alt={competitor.title}/>
                                <div className="bg-white py-6 pb-2 h-full">
                                    {/* <div className="box-subtitle mb-2" dangerouslySetInnerHTML={{ __html: competitor.publishedDate }} /> */}
                                    <h2 className="box-title font-bold mb-3 line-clamp-2" dangerouslySetInnerHTML={{ __html: competitor.title }} />
                                    <div className="text-gray-800 text-lg line-clamp-3 mb-3" dangerouslySetInnerHTML={{ __html: competitor.excerpt }} />
                                    <div className="learnmore-btn mb-0 text1">
                                        <Link className="text-purple-500 inline-flex items-center" to={`${competitor.uri}`}>
                                            <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                            <span className="text-purple-500 font-medium text-sm uppercase">Read now</span>
                                            <svg className="w-6 h-6 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </section>
            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden md:-bottom-10 ">
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
            
        </Layout>
    )
}

export default competitors


